import React from 'react';
import { Link } from 'react-router-dom';

const Legal = () => {
  return (
    <div style={{ backgroundColor: '#121212', color: '#FFFFFF', minHeight: '100vh', padding: '20px' }}>
      <div className="container marketing d-flex flex-column  py-5">
        <h1 className="text-center">Privacy Policy</h1>

        <p className="text-center"><strong>Effective Date: July 1, 2024</strong></p>

        <h3>1. Introduction</h3>
        <p>Welcome to devsNY! This Privacy Policy ("Policy") outlines how devsNY ("we," "us," or "our") collects, uses, and safeguards your personal information when you visit our website, <a href="https://www.devsny.com" style={{ color: '#ea2451' }}>https://www.devsny.com</a> ("Site"). We are committed to protecting your privacy and ensuring a safe online experience.</p>

        <h3>2. Information Collection</h3>
        <p>We collect personal information that you voluntarily provide to us when you interact with our Site. This may include:</p>
        <ul>
          <li><strong>Name</strong></li>
          <li><strong>Email address</strong></li>
          <li><strong>Message</strong></li>
        </ul>
        <p>We collect this information through our consultation form and other interactions on our Site.</p>
        <p>In addition, we use Google Analytics to collect information about your use of our Site. This includes data on your interactions with our Site, such as pages visited, time spent on each page, and other usage statistics. Google Analytics collects this information anonymously.</p>

        <h3>3. Use of Information</h3>
        <p>We use your personal information for the following purposes:</p>
        <ul>
          <li><strong>Responding to Inquiries:</strong> To address your questions and provide the services you request.</li>
          <li><strong>Improving User Experience:</strong> To enhance your experience on our Site through personalization and user feedback.</li>
          <li><strong>Enhancing Site Functionality:</strong> To analyze and improve the functionality and performance of our Site.</li>
        </ul>

        <h3>4. Data Sharing and Disclosure</h3>
        <p>Your privacy is important to us. We do not sell or share your personal information with third parties except in the following circumstances:</p>
        <ul>
          <li><strong>Legal Compliance:</strong> To comply with legal obligations or in response to lawful requests by public authorities.</li>
          <li><strong>Protection of Rights:</strong> To protect and defend our rights and property, prevent fraud, and ensure user safety.</li>
        </ul>

        <h3>5. Data Security</h3>
        <p>We implement industry-standard security measures to protect your personal information. While we strive to use commercially acceptable means to protect your information, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.</p>

        <h3>6. Cookies and Tracking Technologies</h3>
        <p>Our Site may use cookies and similar tracking technologies to enhance user experience and analyze site performance. You can control the use of cookies through your browser settings. Cookies help us understand how you use our Site and provide you with a more personalized experience.</p>

        <h3>7. Children's Privacy</h3>
        <p>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.</p>

        <h3>8. Policy Changes</h3>
        <p>We reserve the right to update this Policy at any time. Any changes will be posted on this page with an updated effective date. Your continued use of the Site after any changes indicates your acceptance of the new Policy.</p>

        <h3>9. Contact Information</h3>
        <p>If you have any questions or concerns about this Policy, please contact us using the contact form available on our Site.</p>

        <h1 className="text-center mt-5">Terms and Conditions</h1>

        <p className="text-center"><strong>Effective Date: July 1, 2024</strong></p>

        <h3>1. Acceptance of Terms</h3>
        <p>By accessing and using <a href="https://www.devsny.com" style={{ color: '#ea2451' }}>https://www.devsny.com</a> ("Site"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with any part of these Terms, please do not use the Site.</p>

        <h3>2. Use of the Site</h3>
        <p>You agree to use the Site for lawful purposes only. Prohibited activities include, but are not limited to:</p>
        <ul>
          <li>Infringing on the rights of others</li>
          <li>Transmitting unlawful, harmful, or offensive content</li>
          <li>Disrupting the normal flow of communication on the Site</li>
        </ul>

        <h3>3. User Submissions</h3>
        <p>You are responsible for ensuring that any information you provide to us is accurate, complete, and up-to-date. Your personal information will be managed in accordance with our Privacy Policy.</p>

        <h3>4. Intellectual Property</h3>
        <p>All content on the Site, including text, graphics, logos, and software, is the property of devsNY or its content suppliers and is protected by intellectual property laws. Unauthorized use of any content on the Site is strictly prohibited.</p>

        <h3>5. Limitation of Liability</h3>
        <p>DevsNY is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Site. This includes, but is not limited to, damages for loss of data or profit, or due to business interruption. The Site is provided "as is" and "as available."</p>

        <h3>6. Indemnification</h3>
        <p>You agree to indemnify, defend, and hold harmless devsNY and its affiliates, officers, agents, and employees from any claims, liabilities, damages, and expenses arising from your use of the Site or violation of these Terms.</p>

        <h3>7. Modifications to Terms</h3>
        <p>We may revise these Terms at any time. Any modifications will be posted on this page with an updated effective date. Your continued use of the Site after any changes indicates your acceptance of the revised Terms.</p>

        <h3>8. Governing Law</h3>
        <p>These Terms are governed by and construed in accordance with the laws of the State of New York, without regard to its conflict of law principles.</p>

        <h3>9. Third-Party Links</h3>
        <p>Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

        <h3>10. User Responsibilities</h3>
        <p>Users are responsible for maintaining the confidentiality of their account information, including usernames and passwords, and for any activity that occurs under their account.</p>

        <h3>11. Entire Agreement</h3>
        <p>These Terms constitute the entire agreement between you and devsNY regarding your use of the Site, superseding any prior agreements between you and devsNY related to your use of this Site.</p>

        <h3>12. Contact Information</h3>
        <p>If you have any questions or concerns about these Terms, please contact us using the contact form available on our Site.</p>
      </div>
    </div>
  );
};

export default Legal;
