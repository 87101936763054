import React from 'react';
import { motion } from 'framer-motion';

const Services = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  return (
    <div className="container-fluid min-vh-100" style={{ backgroundColor: '#121212', color: '#FFFFFF' }}>
      <motion.h1 className="text-center pt-5" style={{ color: '#ea2451' }} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, ease: 'easeOut' }}>
        Our Services
      </motion.h1>
      <motion.p className="text-center pb-3" style={{ color: '#BBBBBB', maxWidth: '800px', margin: '0 auto' }} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, ease: 'easeOut' }}>
        We provide a range of web development services to help businesses build their online presence and reach their digital goals. Explore our services below to see how we can assist you.
      </motion.p>

      <motion.div className="row row-cols-1 row-cols-md-3 g-4 my-4 p-3" initial="hidden" animate="visible" variants={sectionVariants}>
        <motion.div className="col" variants={cardVariants}>
          <div className="card h-100" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="card-body">
              <h5 className="card-title" style={{ color: '#ea2451' }}>Web Development</h5>
              <p className="card-text" style={{ color: '#BBBBBB' }}>Build modern, responsive websites that look great on any device.</p>
            </div>
            <div className="card-footer text-center">
              <motion.button type="button" className="btn btn-primary" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-bs-toggle="modal" data-bs-target="#webDevModal">
                Learn More
              </motion.button>
            </div>
          </div>
        </motion.div>

        <motion.div className="col" variants={cardVariants}>
          <div className="card h-100" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="card-body">
              <h5 className="card-title" style={{ color: '#ea2451' }}>E-commerce Solutions</h5>
              <p className="card-text" style={{ color: '#BBBBBB' }}>Develop robust e-commerce platforms to boost your online sales.</p>
            </div>
            <div className="card-footer text-center">
              <motion.button type="button" className="btn btn-primary" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-bs-toggle="modal" data-bs-target="#ecommerceModal">
                Learn More
              </motion.button>
            </div>
          </div>
        </motion.div>

        <motion.div className="col" variants={cardVariants}>
          <div className="card h-100" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="card-body">
              <h5 className="card-title" style={{ color: '#ea2451' }}>Custom Software</h5>
              <p className="card-text" style={{ color: '#BBBBBB' }}>Develop custom software solutions tailored to your business needs.</p>
            </div>
            <div className="card-footer text-center">
              <motion.button type="button" className="btn btn-primary" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-bs-toggle="modal" data-bs-target="#customSoftwareModal">
                Learn More
              </motion.button>
            </div>
          </div>
        </motion.div>
      </motion.div>

      <motion.h1 className="text-center pt-5" style={{ color: '#ea2451' }} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, ease: 'easeOut' }}>
        Compare Plans
      </motion.h1>
      <motion.p className="text-center pb-3" style={{ color: '#BBBBBB', maxWidth: '800px', margin: '0 auto' }} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8, ease: 'easeOut' }}>
        Choose the plan that best suits your needs. Whether you're just starting out or need a comprehensive solution, we've got you covered.
      </motion.p>

      <motion.div className="row row-cols-1 row-cols-md-3 p-3 text-center" initial="hidden" animate="visible" variants={sectionVariants}>
        <motion.div className="col" variants={cardVariants}>
          <div className="card my-4 rounded-3 shadow-sm" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">Basic</h4>
            </div>
            <div className="card-body">
              <ul className="list-unstyled mt-3 mb-4">
                <li>Up to 5 pages</li>
                <li>5 GB Storage</li>
                <li>Basic SEO</li>
                <li>Email support</li>
              </ul>
              <motion.button type="button" className="w-100 btn btn-lg btn-outline-primary" style={{ color: '#ea2451', borderColor: '#ea2451' }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-bs-toggle="modal" data-bs-target="#basicPlanModal">
                Learn More
              </motion.button>
            </div>
          </div>
        </motion.div>

        <motion.div className="col" variants={cardVariants}>
          <div className="card my-4 rounded-3 shadow-sm" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="card-header py-3">
              <h4 className="my-0 fw-normal">Pro</h4>
            </div>
            <div className="card-body">
              <ul className="list-unstyled mt-3 mb-4">
                <li>Up to 20 pages</li>
                <li>20 GB Storage</li>
                <li>Advanced SEO</li>
                <li>Priority Email Support</li>
              </ul>
              <motion.button type="button" className="w-100 btn btn-lg btn-primary" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-bs-toggle="modal" data-bs-target="#proPlanModal">
                Learn More
              </motion.button>
            </div>
          </div>
        </motion.div>

        <motion.div className="col" variants={cardVariants}>
          <div className="card my-4 rounded-3 shadow-sm" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF', borderColor: '#ea2451' }}>
            <div className="card-header py-3" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }}>
              <h4 className="my-0 fw-normal" style={{ color: '#FFFFFF' }}>Enterprise</h4>
            </div>
            <div className="card-body">
              <ul className="list-unstyled mt-3 mb-4">
                <li>Up to 50 pages</li>
                <li>50 GB Storage</li>
                <li>Full SEO Suite</li>
                <li>Phone & Priority Email Support</li>
              </ul>
              <motion.button type="button" className="w-100 btn btn-lg btn-primary" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} data-bs-toggle="modal" data-bs-target="#enterprisePlanModal">
                Learn More
              </motion.button>
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Modals */}
      <div className="modal fade" id="webDevModal" tabIndex="-1" aria-labelledby="webDevModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="webDevModalLabel">Web Development</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h5>Web Design & Development Done Differently</h5>
              <p>At our core, we believe in crafting websites the old-fashioned way – by meticulously writing each line of code by hand. We steer clear of WordPress, frameworks, or page builders. This hands-on approach grants us unparalleled control over the design and functionality of your website, ensuring it operates at lightning speed without the bloat of unnecessary code.</p>
              <p>Hand-built websites significantly outperform most WordPress sites and drag-and-drop page builders, which can often be costly. In the digital realm, faster and more streamlined websites invariably come out on top.</p>
              <h5>Lifetime Updates for Your Website</h5>
              <p>The digital landscape is constantly evolving. Google updates its search algorithm standards regularly, and web accessibility guidelines for users with screen readers are always changing. To keep your website current, we offer lifetime updates, ensuring your site never falls behind and always meets the latest standards.</p>
              <h5>Design Updates</h5>
              <p>Want a fresh look? We will rebuild your website with a new design at no extra cost.</p>
              <h5>Web Accessibility</h5>
              <p>We stay on top of the latest guidelines to ensure your website complies with all web accessibility standards.</p>
              <h5>Search Engine Updates</h5>
              <p>Your website will continuously adhere to the newest Google search guidelines for optimal ranking.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ecommerceModal" tabIndex="-1" aria-labelledby="ecommerceModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="ecommerceModalLabel">E-commerce Solutions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Our e-commerce solutions are designed to help you sell more online. We create robust platforms that are easy to manage and offer a seamless shopping experience for your customers.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="customSoftwareModal" tabIndex="-1" aria-labelledby="customSoftwareModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="customSoftwareModalLabel">Custom Software</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              We develop custom software solutions that are tailored to your business needs. Whether you need a CRM, ERP, or any other software, we can create a solution that fits your requirements perfectly.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="basicPlanModal" tabIndex="-1" aria-labelledby="basicPlanModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="basicPlanModalLabel">Basic Plan</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Our Basic Plan includes up to 5 pages, 5 GB of storage, basic SEO, and email support. It's perfect for small businesses or personal projects just starting out online.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="proPlanModal" tabIndex="-1" aria-labelledby="proPlanModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="proPlanModalLabel">Pro Plan</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Our Pro Plan includes up to 20 pages, 20 GB of storage, advanced SEO, and priority email support. Ideal for growing businesses looking to expand their online presence.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="enterprisePlanModal" tabIndex="-1" aria-labelledby="enterprisePlanModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="enterprisePlanModalLabel">Enterprise Plan</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Our Enterprise Plan includes up to 50 pages, 50 GB of storage, a full SEO suite, and phone & priority email support. It's designed for large businesses with extensive online requirements.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="seoModal" tabIndex="-1" aria-labelledby="seoModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="seoModalLabel">Search Engine Optimization</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h5>Search Engine Optimization for 2021</h5>
              <p>Beware of anyone promising to get you on the first page of Google in three months – unless your site has been featured by major publications like The New York Times, achieving top rankings can take years.</p>
              <p>We stay ahead of the curve with the latest SEO techniques for 2021:</p>
              <ul>
                <li>Keyword-Centered Content & Blogs</li>
                <li>Mobile-First Optimization for the Best Experience</li>
                <li>Clean & Organized Code Structure for Web Crawlers</li>
                <li>Fully Responsive Design for Mobile, Tablet, & Desktop</li>
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="brandingModal" tabIndex="-1" aria-labelledby="brandingModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="brandingModalLabel">Logos, Graphic Design, & Branding</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h5>Logos, Graphic Design, & Branding</h5>
              <p>Our in-house graphic designer offers top-notch logo and graphic design services. Whether you need a brand new logo or a touch-up for your existing one, we can help elevate your business’s brand.</p>
              <ul>
                <li><strong>Logos Starting at $350</strong> Prices may vary based on complexity, number of hours, and revisions.</li>
                <li><strong>Social Media Graphics</strong> Stand out on social media with custom graphics. Contact us for plans and rates.</li>
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="googleBusinessModal" tabIndex="-1" aria-labelledby="googleBusinessModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }}>
            <div className="modal-header">
              <h5 className="modal-title" id="googleBusinessModalLabel">Google Business Profile</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              We also specialize in optimizing and managing your Google, Bing, and Yahoo Business Profiles, ensuring your business is well-represented across all major search engines.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
