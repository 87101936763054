import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Home = () => {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();
  const controls5 = useAnimation();

  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.5 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.5 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref4, inView4] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView1) {
      controls1.start('visible');
    }
  }, [controls1, inView1]);

  React.useEffect(() => {
    if (inView2) {
      controls2.start('visible');
    }
  }, [controls2, inView2]);

  React.useEffect(() => {
    if (inView3) {
      controls3.start('visible');
    }
  }, [controls3, inView3]);

  React.useEffect(() => {
    if (inView4) {
      controls4.start('visible');
    }
  }, [controls4, inView4]);

  React.useEffect(() => {
    if (inView5) {
      controls5.start('visible');
    }
  }, [controls5, inView5]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const featureVariantsLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const featureVariantsRight = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const featureItemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <div style={{ backgroundColor: '#121212', color: '#FFFFFF', minHeight: '100vh' }}>
      <Helmet>
        <title>devsNY - Web Development and Software Engineering</title>
        <meta name="description" content="Sign up for a free consultation with DevsNY and discover how we can help you build a powerful online presence for your business." />
        <meta property="og:title" content="DevsNY - Web Development and Software Engineering" />
        <meta property="og:description" content="Sign up for a free consultation with DevsNY and discover how we can help you build a powerful online presence for your business." />
        <meta property="og:url" content="https://www.devsny.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="%PUBLIC_URL%/og-image.png" />
        <meta name="twitter:title" content="DevsNY - Web Development and Software Engineering" />
        <meta name="twitter:description" content="Sign up for a free consultation with DevsNY and discover how we can help you build a powerful online presence for your business." />
        <meta name="twitter:image" content="%PUBLIC_URL%/twitter-image.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "DevsNY",
              "url": "https://www.devsny.com/",
              "logo": "%PUBLIC_URL%/logo192.png",
              "sameAs": [
                "https://www.facebook.com/devsny",
                "https://www.twitter.com/devsny",
                "https://www.linkedin.com/company/devsny"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-212-555-0123",
                "contactType": "Customer Service"
              }
            }
          `}
        </script>
      </Helmet>
      <main className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <motion.div
              ref={ref1}
              initial="hidden"
              animate={controls1}
              variants={sectionVariants}
            >
              <motion.h1 className="display-4 fw-bold lh-1 mb-3" style={{ color: '#ea2451', fontSize: '55px' }} variants={itemVariants}>
                We are devsNY.
              </motion.h1>
              <motion.p className="my-4" style={{ fontSize: '1.92em', fontFamily: '"Courier New", Courier, "Lucida Console", monospace' }} variants={itemVariants}>
                <span style={{ color: '#ea2451', fontSize: '1em', verticalAlign: 'middle' }}>&lt;</span>
                <span style={{ verticalAlign: 'middle' }}> your partners in </span>
                <span style={{ color: '#ea2451', fontSize: '1.1em', verticalAlign: 'middle' }}> code </span>
                <span style={{ color: '#ea2451', fontSize: '1em', verticalAlign: 'middle' }}> /&gt;</span>
              </motion.p>
              <motion.p className="col-lg-10 fs-5 pb-3" variants={itemVariants}>
                At devsNY, we create 100% hand-coded websites for superior performance. No page builders or WordPress.
                Complete the form for a free consultation with our expert developers and start your journey to success!
              </motion.p>
            </motion.div>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <motion.form className="p-4 p-md-5 border rounded-3" style={{ backgroundColor: '#1F1F1F' }}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            >
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingEmail"
                  placeholder="name@example.com"
                  style={{ backgroundColor: '#121212', color: '#FFFFFF' }}
                />
                <label htmlFor="floatingEmail" style={{ color: '#BBBBBB' }}>Email address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingName"
                  placeholder="Your Name"
                  style={{ backgroundColor: '#121212', color: '#FFFFFF' }}
                />
                <label htmlFor="floatingName" style={{ color: '#BBBBBB' }}>Name</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  id="floatingMessage"
                  placeholder="Your Message"
                  style={{ backgroundColor: '#121212', color: '#FFFFFF', height: '150px' }}
                ></textarea>
                <label htmlFor="floatingMessage" style={{ color: '#BBBBBB' }}>Message</label>
              </div>
              <div className="checkbox mb-3">
                <label style={{ color: '#BBBBBB', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                  <input type="checkbox" value="agree-terms" style={{ marginRight: '5px' }} /> I agree to the
                  <Link className="nav-link" to="/legal" style={{ color: '#ea2451', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }}>
                    terms
                  </Link>
                  and
                  <Link className="nav-link" to="/legal" style={{ color: '#ea2451', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer', marginLeft: '5px' }}>
                    privacy policy
                  </Link>.
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <Link className="nav-link w-100" to="/contact" style={{ color: '#ffffff' }}>
                  <motion.button
                    type="button"
                    className="btn btn-primary btn-lg w-100 fw-bold"
                    style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }}
                    whileHover={{ scale: 1.1, rotate: 5 }}
                    whileTap={{ scale: 0.9, rotate: -5 }}
                  >
                    Let's talk!
                  </motion.button>
                </Link>
              </div>
            </motion.form>
          </div>
        </div>
      </main>
      <section className="container p-4">
        <motion.div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg" style={{ backgroundColor: '#1F1F1F' }}
          ref={ref2}
          initial="hidden"
          animate={controls2}
          variants={sectionVariants}
        >
          <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
            <h2 className="display-4 fw-bold lh-1" style={{ color: '#ea2451' }}>
              What we do
            </h2>
            <p className="lead">
              At devsNY, we specialize in crafting bespoke web design and development solutions for small businesses across the US. Our dedicated team meticulously hand-codes every aspect of your website, ensuring optimal performance. This precision not only attracts more customers to your site but also drives increased revenue for your business.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-1 mb-lg-3">
              <motion.button
                type="button"
                className="btn btn-primary btn-lg px-4 me-md-2 fw-bold mb-2"
                style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }}
                whileHover={{ scale: 1.1, rotate: 5 }}
                whileTap={{ scale: 0.9, rotate: -5 }}
              >
                <Link className="nav-link" to="/contact" style={{ color: '#ffffff' }}>Send us a message</Link>
              </motion.button>
            </div>
          </div>
          <div className="col-lg-5 text-center text-lg-end p-3 p-lg-5">
            <motion.img
              src="https://via.placeholder.com/300x200.png?text=Placeholder+Image"
              alt="Placeholder Image"
              className="img-fluid"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            />
          </div>
        </motion.div>
      </section>
      <div className="row featurette">
        <motion.div className="col-md-7"
          ref={ref3}
          initial="hidden"
          animate={controls3}
          variants={featureVariantsLeft}
        >
          <h2 className="featurette-heading fw-normal lh-1 p-5" style={{ color: '#ea2451' }}>Mobile-First, fully responsive design.</h2>
          <p className="lead p-5">We start by designing your site for mobile devices, ensuring a seamless and efficient user experience on smaller screens. From there, we progressively enhance the design to adapt beautifully to tablet and desktop devices. This approach guarantees that your site looks great and functions flawlessly on any screen size, providing an optimal experience for all users.</p>
        </motion.div>
        <motion.div className="col-md-5"
          ref={ref3}
          initial="hidden"
          animate={controls3}
          variants={featureVariantsLeft}
        >
          <svg className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto p-5" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"></rect><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg>
        </motion.div>
      </div>
      <div className="row featurette">
        <motion.div className="col-md-7 order-md-2"
          ref={ref4}
          initial="hidden"
          animate={controls4}
          variants={featureVariantsRight}
        >
          <h2 className="featurette-heading fw-normal lh-1 p-5" style={{ color: '#ea2451' }}>Analytics at a glance.</h2>
          <p className="lead p-5">With your own personalized analytics dashboard, you'll have comprehensive insights into your website's performance. Easily track visitor behavior, monitor traffic sources, and analyze key metrics to understand how your site is performing on the web. This powerful tool enables you to make data-driven decisions, optimize your online presence, and ensure that you are effectively reaching and engaging your audience.</p>
        </motion.div>
        <motion.div className="col-md-5 order-md-1"
          ref={ref4}
          initial="hidden"
          animate={controls4}
          variants={featureVariantsRight}
        >
          <svg className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto p-5" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"></rect><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg>
        </motion.div>
      </div>
      <div className="row featurette">
        <motion.div className="col-md-7"
          ref={ref5}
          initial="hidden"
          animate={controls5}
          variants={featureVariantsLeft}
        >
          <h2 className="featurette-heading fw-normal lh-1 p-5" style={{ color: '#ea2451' }}>Search Engine Optimization.</h2>
          <p className="lead p-5">With 60% of all internet traffic coming from mobile devices, we ensure your site is optimized to perform its best in search engines for mobile users. By prioritizing mobile optimization, we help you capture this significant portion of web traffic, enhancing your visibility and accessibility to potential clients on their smartphones and tablets​.</p>
        </motion.div>
        <motion.div className="col-md-5"
          ref={ref5}
          initial="hidden"
          animate={controls5}
          variants={featureVariantsLeft}
        >
          <svg className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto p-5" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"></rect><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg>
        </motion.div>
      </div>
      <section className="container px-4 py-5">
        <h2 className="pb-2 border-bottom" style={{ color: '#ea2451' }}>Why Choose Us?</h2>
        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
          <motion.div
            className="col d-flex flex-column align-items-start gap-2"
            initial="hidden"
            whileInView="visible"
            variants={featureVariantsLeft}
            viewport={{ once: true, amount: 0.1 }}
          >
            <motion.div variants={featureItemVariants}>
              <h3 className="fw-bold" style={{ color: '#ffffff' }}>$0 Down. $100 Per Month.</h3>
              <h3 className="fw-bold" style={{ color: '#ffffff' }}>12 Month Minimum Contract.</h3>
              <p style={{ color: '#BBBBBB' }}>
                Start with a standard 5-page small business website for just $0 down. Need more? Custom pricing is available based on the scope of work, number of additional pages, and time involved.
              </p>
            </motion.div>
          </motion.div>
          <div className="col">
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={featureVariantsLeft}
              viewport={{ once: true, amount: 0.1 }}
            >
              <div className="row row-cols-1 row-cols-sm-2 g-4">
                <motion.div
                  className="col d-flex flex-column gap-2"
                  variants={featureItemVariants}
                >
                  <div className="feature-icon-small d-inline-flex align-items-center justify-content-center fs-4 rounded-3" style={{ width: '40px', height: '40px', backgroundColor: '#ea2451' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-piggy-bank-fill" viewBox="0 0 16 16">
                      <path d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069q0-.218-.02-.431c.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a1 1 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.74.74 0 0 0-.375.562c-.024.243.082.48.32.654a2 2 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595m7.173 3.876a.6.6 0 0 1-.098.21l-.044-.025c-.146-.09-.157-.175-.152-.223a.24.24 0 0 1 .117-.173c.049-.027.08-.021.113.012a.2.2 0 0 1 .064.199m-8.999-.65a.5.5 0 1 1-.276-.96A7.6 7.6 0 0 1 7.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 1 1-.287.958A6.6 6.6 0 0 0 7.964 4.5c-.64 0-1.255.09-1.826.254ZM5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0"/>
                    </svg>
                  </div>
                  <h4 className="fw-semibold mb-0" style={{ color: '#FFFFFF' }}>Hosting Fees Included</h4>
                  <p style={{ color: '#BBBBBB' }}>
                    All-Inclusive Hosting fees are seamlessly integrated into your monthly payment. Enjoy hassle-free, all-in-one billing for your website.
                  </p>
                </motion.div>
                <motion.div
                  className="col d-flex flex-column gap-2"
                  variants={featureItemVariants}
                >
                  <div className="feature-icon-small d-inline-flex align-items-center justify-content-center fs-4 rounded-3" style={{ width: '40px', height: '40px', backgroundColor: '#ea2451' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
                    </svg>
                  </div>
                  <h4 className="fw-semibold mb-0" style={{ color: '#FFFFFF' }}>Your Site, Your Control</h4>
                  <p style={{ color: '#BBBBBB' }}>
                    You retain ownership of your domain, content, listings, and profiles. Cancel anytime without any fees or hassle. Enjoy full flexibility and control over your online presence.
                  </p>
                </motion.div>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={featureVariantsLeft}
              viewport={{ once: true, amount: 0.1 }}
            >
              <div className="row row-cols-1 row-cols-sm-2 g-4">
                <motion.div
                  className="col d-flex flex-column gap-2"
                  variants={featureItemVariants}
                >
                  <div className="feature-icon-small d-inline-flex align-items-center justify-content-center fs-4 rounded-3" style={{ width: '40px', height: '40px', backgroundColor: '#ea2451' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                    </svg>
                  </div>
                  <h4 className="fw-semibold mb-0" style={{ color: '#FFFFFF' }}>24/7 Customer Service</h4>
                  <p style={{ color: '#BBBBBB' }}>
                    Call direct anytime day or night, no phone trees or automated responses.
                  </p>
                </motion.div>
                <motion.div
                  className="col d-flex flex-column gap-2"
                  variants={featureItemVariants}
                >
                  <div className="feature-icon-small d-inline-flex align-items-center justify-content-center fs-4 rounded-3" style={{ width: '40px', height: '40px', backgroundColor: '#ea2451' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                      <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a10 10 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733q.086.18.138.363c.077.27.113.567.113.856s-.036.586-.113.856c-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.2 3.2 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.8 4.8 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                    </svg>
                  </div>
                  <h4 className="fw-semibold mb-0" style={{ color: '#FFFFFF' }}>Satisfaction Guarantee</h4>
                  <p style={{ color: '#BBBBBB' }}>
                    We are confident in the quality of our work. If we do not meet your project targets, we offer code reviews for free.
                  </p>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Link className="nav-link" to="/contact" style={{ color: '#ffffff' }}>
            <motion.button
              type="button"
              className="btn btn-primary btn-lg px-4 me-md-2 fw-bold mb-2"
              style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }}
              whileHover={{ scale: 1.1, rotate: 5 }}
              whileTap={{ scale: 0.9, rotate: -5 }}
            >
              Tell us about your project
            </motion.button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Home;
