import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import './Header.css'; // Import the custom CSS

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg border-bottom border-color" style={{ backgroundColor: '#000', color: '#ffffff' }}>
      <div className="container-fluid">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#ea2451" className="bi bi-braces-asterisk mx-2" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M1.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C2.25 2 1.49 2.759 1.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6M14.886 7.9v.164c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456v-1.3c-1.114 0-1.49-.362-1.49-1.456V4.352C14.51 2.759 13.75 2 12.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6M7.5 11.5V9.207l-1.621 1.621-.707-.707L6.792 8.5H4.5v-1h2.293L5.172 5.879l.707-.707L7.5 6.792V4.5h1v2.293l1.621-1.621.707.707L9.208 7.5H11.5v1H9.207l1.621 1.621-.707.707L8.5 9.208V11.5z"/>
        </svg>
        <Link className="navbar-brand mx-3" to="/" style={{ color: '#ffffff' }}>devsNY</Link>
        
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/" style={{ color: '#ea2451' }}>Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" style={{ color: '#ea2451' }}>About</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services" style={{ color: '#ea2451' }}>Services</Link>
            </li>
          </ul>
          <div className="d-flex w-100 justify-content-center justify-content-lg-end my-2 my-lg-0">
            <button type="button" className="btn btn-primary btn-md px-4 fw-bold me-lg-2" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }}>
              <Link className="nav-link" to="/contact" style={{ color: '#ffffff' }}>Speak with our devs</Link>
            </button>
          </div>
        </div>
        
      </div>
    </nav>
  );
};

export default Header;
