import React from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className="container-fluid d-flex align-items-center justify-content-center py-4" style={{ backgroundColor: '#121212', color: '#FFFFFF', minHeight: '100vh' }}>
      <main className="form-signin w-100 m-auto p-5">
        <form>
          <h1 className="h3 mb-3 fw-normal" style={{ color: '#ea2451' }}>Contact Us</h1>

          <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }} />
            <label htmlFor="floatingInput" style={{ color: '#BBBBBB' }}>Email address</label>
          </div>
          <div className="form-floating mb-3">
            <input type="text" className="form-control" id="floatingName" placeholder="Your Name" style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF' }} />
            <label htmlFor="floatingName" style={{ color: '#BBBBBB' }}>Your Name</label>
          </div>
          <div className="form-floating mb-3">
          <textarea
                  className="form-control"
                  id="floatingMessage"
                  placeholder="Your Message"
                  style={{ backgroundColor: '#1F1F1F', color: '#FFFFFF', height: '150px' }}
                ></textarea>            <label htmlFor="floatingMessage" style={{ color: '#BBBBBB' }}>Your Message</label>
          </div>
          <label className='mb-3' style={{ color: '#BBBBBB', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
    <input type="checkbox" value="agree-terms" style={{ marginRight: '5px' }} /> I agree to the
    <Link className="nav-link" to="/about" style={{ color: '#ea2451', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer', marginLeft: '5px', marginRight: '5px' }}>
      terms
    </Link>
    and
    <Link className="nav-link" to="/about" style={{ color: '#ea2451', textDecoration: 'underline', background: 'none', border: 'none', padding: 0, font: 'inherit', cursor: 'pointer', marginLeft: '5px' }}>
      privacy policy
    </Link>.
  </label>
          <button className="btn btn-primary w-100 py-2" type="submit" style={{ backgroundColor: '#ea2451', borderColor: '#ea2451' }}>Send Message</button>
        </form>
      </main>


    </div>
  );
};

export default Contact;
