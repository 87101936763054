import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const About = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  if (inView) {
    controls.start('visible');
  }

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        when: 'beforeChildren',
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  return (
    <div style={{ backgroundColor: '#121212', color: '#FFFFFF', minHeight: '100vh' }}>
      <motion.div className="container marketing d-flex flex-column align-items-center text-center py-5"
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <motion.h1 className="fw-bold p-4" style={{ color: '#ea2451' }} variants={itemVariants}>About Us</motion.h1>
        
        <motion.p className="lead m-4" style={{ color: '#BBBBBB', maxWidth: '800px' }} variants={itemVariants}>
          Welcome to our company! We are dedicated to delivering top-quality software solutions. Our co-founders, Zach and Justin, bring a wealth of experience and expertise in software development, project management, and user experience design. Together, they lead our team in creating innovative, reliable, and user-friendly applications that meet the needs of our clients.
        </motion.p>

        <div className="row text-center my-4 p-5">
          <motion.div className="col-lg-6 mb-5"
            initial="hidden"
            whileInView="visible"
            variants={sectionVariants}
            viewport={{ once: true, amount: 0.1 }}
          >
            <motion.svg className="bd-placeholder-img rounded-circle m-4" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Justin" preserveAspectRatio="xMidYMid slice" focusable="false" variants={imageVariants}><title>Justin</title><rect width="100%" height="100%" fill="#343a40"></rect></motion.svg>
            <motion.h2 className="fw-normal" style={{ color: '#ea2451' }} variants={itemVariants}>Justin</motion.h2>
            <motion.p style={{ color: '#BBBBBB' }} variants={itemVariants}>Justin brings a wealth of experience in software development and project management, ensuring every project is delivered on time and to the highest standards.</motion.p>
          </motion.div>
          <motion.div className="col-lg-6"
            initial="hidden"
            whileInView="visible"
            variants={sectionVariants}
            viewport={{ once: true, amount: 0.1 }}
          >
            <motion.svg className="bd-placeholder-img rounded-circle m-4" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Zach" preserveAspectRatio="xMidYMid slice" focusable="false" variants={imageVariants}><title>Zach</title><rect width="100%" height="100%" fill="#343a40"></rect></motion.svg>
            <motion.h2 className="fw-normal" style={{ color: '#ea2451' }} variants={itemVariants}>Zach</motion.h2>
            <motion.p style={{ color: '#BBBBBB' }} variants={itemVariants}>Zach's expertise in user experience and interface design helps create intuitive and engaging applications that meet user needs and exceed expectations.</motion.p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
